<template>
  <div class="container mx-auto py-20 text-center max-w-2xl mdmax:py-10 mdmax:px-4">
    <ImageItem :source="require('@/assets/images/illustration/Checking.svg')" />
    <div class="font-bold text-3.5xl mdmax:text-2xl under4inch:text-xl mt-10 mb-5">Thank you for signing up!</div>
    <div class="mb-5 under4inch:text-sm">An invitation will be sent to <span class="font-bold">{{ email }}</span> with all the details. Looking forward to meeting you at the {{ eventName }}!</div>
    <router-link to="/" class="text-yellow">Click here to return to Home.</router-link>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'thank-you-event',
  components: {
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem')
  },
  data: () => ({
    email: ''
  }),
  created() {
    this.getUserData()
  },
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapGetters('data', ['emailEvent']),
    eventName() {
      const text = this.$route.params.slug.split('-')
      const textArr = []
      let textFix = ''
      for (let i = 0; i < text.length; i++) {
        const lowerCase = text[i]
        const camelCase = lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1)
        textArr.push(camelCase)
      }
      for (let k = 0; k < textArr.length; k++) {
        k === textArr.length - 1 ? (textFix += `${textArr[k]}`) : (textFix += `${textArr[k]} `)
      }
      return textFix
    }
  },
  methods: {
    ...mapActions('prakerja', ['getUserProfile']),
    ...mapActions('common', ['showLoading', 'hideLoading']),
    getUserData() {
      if (this.isLoggedIn) {
        this.getUserProfile().then((res) => {
          this.email = res.email
        })
      } else {
        this.email = this.emailEvent
      }
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
